import React from 'react';
import styles from './index.module.scss';
import './index.scss';
import { Template2Feature } from '@tradesorg-chris/component-library-waas-templates.template-2-feature';

const BusinessFeature = ({ data }) => {
    const { slogan, heading, features } = data;

    return (
        <div
            id="features"
            className={`businessFeatureWrapper ${styles.businessFeatureWrapper} w-auto grid grid-cols-1 lg:grid-cols-2 gap-4 mx-20px md-1:mx-40px lg:mx-10 xl:mx-24 xl:mb-10 mt-60px lg:mt-20 lg:pt-12 px-20px md-1:px-40px lg:px-10 pb-40px md-1:pb-50px md:p-10 xl:pl-100px xl:pr-120px xl:max-w-1170px`}
        >
            <div className={`${styles.titleWrapper} w-full pt-40px lg:pt-32px lg:pb-19px`}>
                <div className={`${styles.subTitle} text-xs`}>{slogan}</div>
                <div className={`${styles.title} text-2xl lg:text-4xl font-semibold`}>
                    { heading }
                </div>
            </div>
            <div className="">
                <div
                    className={`wrapper grid md-1:grid-cols-2 lg:grid-rows-auto pt-32px gap-30px`}
                >
                    { features?.map((item) => {
                        console.log(item)
                        return (
                            <Template2Feature
                                icon={item.icon}
                                title={item.name}
                                description={item.description}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default BusinessFeature;
