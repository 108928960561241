import React from 'react';
import { Progress } from '@tradesorg-chris/component-library-waas-templates.template-2-progress';
import { ServiceHeading } from '@tradesorg-chris/component-library-waas-templates.template-2-service-heading';
import styles from './index.module.scss';
import Markdown from 'markdown-to-jsx';
import { useWindowDimensions } from '@utils/customHooks';

const OurProcess = ({ data }) => {
    const {slogan, heading,  process } = data;

    const { width } = useWindowDimensions();
 
    return (
        <div
            id="our-process"
            className={`ourProcessComponentWrapper px-5 md:1-px-10 xl:pt-50 xl:pb-50 md-1:pl-40px md-1:pr-40px xl:pl-98px xl:pr-98px 
        pt-50px pb-50px`}
            
        >
            <ServiceHeading
                sloganText={slogan}
                heading={heading}
                centered={width > 640 ? true : false}
                className={{
                    heading: 'mt-5px xs:mb-10 xl:mb-52px',
                }}
            />
            <div className="grid grid-cols-1 mt-5">
                {process && process[0] && process.map((item,index) => (
                        <Progress
                            key={'process-item'}
                            title={item.title}
                            content={<Markdown>{item.longDescription ? item.longDescription : item.shortDescription ? item.shortDescription : ''}</Markdown>}
                            
                            className={{
                                displayLine: 'w-200px ml-30px',
                                badge: `w-10 h-10 text-sm font-semibold pt-6px pb-6px py-3 ${styles.badge}`,
                                wrapper: `${styles.pr0Important} xs:mb-0`,
                                content: `font-13px`,
                                title: `font-15px`
                            }}
                        />
                    
                ))}
            </div>
        </div>
    );
};

export default OurProcess;
