import React from 'react';
import { ServiceHeading } from '@tradesorg-chris/component-library-waas-templates.template-2-service-heading';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps';
import iconFacebook from '@assets/icons/template-2/facebook.svg';
import iconInstagram from '@assets/icons/template-2/instagram.svg';
import iconPinterest from '@assets/icons/template-2/pinterest.svg';
import iconHouse from '@assets/icons/template-2/house.svg';
import iconLinkedin from '@assets/icons/template-2/linkedin.svg';
import iconHouzz from '@assets/icons/template-2/houzz.svg';
import { nanoid } from 'nanoid';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';

const ContactUs = ({ data }) => {
    const { slogan, title, address, radius, email, phone, socialProfiles, titleFollowUs } = data

    const MyMapComponent = withScriptjs(
        withGoogleMap((props) => (
            <GoogleMap defaultZoom={10} defaultCenter={{ lat: parseFloat(address?.latitude) , lng: parseFloat(address?.longitude) }}>
                {props.isMarkerShown && <Marker position={{ lat: parseFloat(address?.latitude) , lng: parseFloat(address?.longitude) }} />}

                <Circle
                    defaultCenter={{ lat: parseFloat(address?.latitude) , lng: parseFloat(address?.longitude) }}
                    radius={parseInt(radius) * 100}
                    options={{
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                    }}
                />
            </GoogleMap>
        )),
    );

    const handleRenderIcon = (key) => {
        if (key.includes('facebook')) {
            return iconFacebook;
        }
        if (key.includes('intagram')) {
            return iconInstagram;
        }
        if (key.includes('house')) {
            return iconHouse;
        }
        if (key.includes('houzz')) {
            return iconHouzz;
        }
        if (key.includes('pinterest')) {
            return iconPinterest;
        }

        return iconLinkedin;
    };

    return (
        <div className="contactUsContainer px-5 md-1:px-10 xl:px-24 pb-10 xl:pb-20 md-1:flex" id="contact">
            <div className="contact-us-info xl:px-20 md-1:w-4/12 md-1:mt-51px">
                <ServiceHeading 
                    sloganText={slogan} 
                    heading={title} 
                    half={true}
                    className={{
                        'heading': 'mb-3 md-1:mb-0 md-1:mt-5px',
                        'textWrapper': 'md-1:w-full max-w-2xl'
                    }}
                />
                <a href={`tel:${phone}`}>
                <p className="mt-17px md-1:mt-8 not-italic tracking-normal text-left  leading-4">
                    { formatPhoneNumber(phone)  }
                </p>
                </a>
                <a href={`mailto:${email}`}>
                <p className="mt-17px not-italic tracking-normal text-left leading-4">
                    { email }
                </p>
                </a>
                <p className="mt-17px not-italic tracking-normal text-left leading-4">
                    { address?.singleLineAddress }
                </p>
                {!!socialProfiles.length &&  <div
                    className={`text-sm flex text-sm text-gray-500 mt-47px mt-8 uppercase items-start`}
                >
                    { titleFollowUs }
                </div>}
               
                <div className="flex mt-17px cursor-pointer space-x-5 justify-start">
                    {socialProfiles?.map((item) => {
                        return (
                            <a href={item.url} target="_blank"  rel="noopener">                                        
                            <img
                                key={nanoid()}
                                src={handleRenderIcon(item.name)}
                                alt={`icon-${item.name}`}
                            />
                            </a>
                        );
                    })}
                </div>
            </div>
            <div className="contact-map md-1:w-7/12 -mx-5 md-1:mx-0 mt-30px md-1:mt-4 xl:mt-0">
                <MyMapComponent
                        isMarkerShown
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.GATSBY_G_MAP}&libraries=geometry,drawing,places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        </div>

    );
};
export default ContactUs;
